import directusUrl from "./urlString";

async function asyncForEach(anArray, callback) {
    for (let index = 0; index < anArray.length; index++) {
      await callback(anArray[index], index, anArray);
    }
  }

export default async function loadTopics(array){
    console.log(array)
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    try {
        await asyncForEach(array,async(element,index,array)=>{
            //console.log("element",element)
            const el = await fetch(directusUrl()+'items/gw_Topic/' + element.test_Topic_topic_id,requestOptions);
            const json = await el.json();
            array[index]=json.data;
        });
        
    } catch (error) {
        console.log(error);
        throw new Error(error);  
    }
    return array
    
}