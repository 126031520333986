import directusUrl from "./urlString";

const editUserDirectus = async (anId) => {
    console.log("An edit received",anId)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        "registration": "Registered to GROUPS.AWR.ORG without an URL"
    });

    var requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };
    return fetch(directusUrl() + "items/gw_User/" + anId, requestOptions)
}

export default editUserDirectus;