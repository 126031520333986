import directusUrl from "./urlString";

export default async function webhookDirectus(anUser){
	var myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");

	var raw = JSON.stringify(anUser);

	var requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow'
	};

	return fetch("https://cdedata.directus.app/flows/trigger/e4478533-4d9b-4a47-8084-f8c10705f52b", requestOptions)
		.then(response => response.text())
		.then(result => console.log(result))
		.catch(error => console.log('error', error));
}

