import directusUrl from "./urlString";

export default async function newUserDirectus(anUser) {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let user;

    var raw = JSON.stringify({
        "email": anUser.email,
        "timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
        "name": anUser.name ? anUser.name : '',
        "additional_comments": anUser.additionalComments ? anUser.additionalComments : null
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    const newUser = await fetch(directusUrl() + "items/gw_User", requestOptions);
    const newUserResponse = await newUser.json();
    console.log("New userDirectus",newUserResponse.data);
    user = newUserResponse.data.user_id;
    return user //return a user ID
}