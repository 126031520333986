import directusUrl from "./urlString";

export default async function checkUserDirectus(anEmail) {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    const emailExists = await fetch(directusUrl() + "items/gw_User?filter[email][_eq]=" + anEmail, requestOptions);
    const response = await emailExists.json();
    console.log(response);
    let user = response.data[0];
    return user;
}