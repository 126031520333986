'use client'
import React, { useState, useEffect } from "react";
import styles from "../styles/register.module.css"
import {
    Container,
    Flex,
    Box,
    Heading,
    Text,
    Button,
    VStack,
    Tooltip,
    Stack,
    FormControl,
    FormLabel,
    FormHelperText,
    Input,
    InputGroup,
    InputLeftElement,
    Textarea, Alert, AlertIcon
} from '@chakra-ui/react';
import { Divider } from '@chakra-ui/react'
import {
    MdLocationOn,
    MdOutlineEmail,
} from 'react-icons/md'
import { BsPerson } from 'react-icons/bs';
import { useToast } from '@chakra-ui/react';
import formatDate from "../utils/formatDate";
import formatDay from "../utils/formatDay";
import formatTime from "../utils/formatTime";
import directusUrl from "../utils/urlString";
import checkIfEmail from "./checkIfEmail";
import checkUserDirectus from "./checkUserDirectus";
import newUserDirectus from "./newUserDirectus";
import registerToEvent from "./registerToEvent";
import editUserDirectus from "./editUserDirectus";
import webhookDirectus from "./webHookDirectus";

export default function Register({ studyId }) {
    const [event, setEvent] = useState([]);
    const [showSend, setShowSend] = React.useState(false);
    const toast = useToast();
    //FORM STATE
    const [valueName, setValueName] = React.useState('');
    const [valueEmail, setValueEmail] = React.useState('');
    const [validEmail, setValidEmail] = React.useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    //END FORM STATE

    //START FORM HANDLERS
    const handleChangeName = (event) => setValueName(event.target.value);
    const handleChangeEmail = (event) => {
        setValueEmail(event.target.value);
        if (checkIfEmail(event.target.value)) {
            setShowSend(true)
        } else {
            setShowSend(false)
        }
    };
    const resetForms = () => {
        setValueEmail('');
        setValueName('');
        setValueTextarea('');
        setShowSend(false)
    }
    let [valueTextarea, setValueTextarea] = React.useState('');

    let handleInputChangeTextarea = (e) => {
        let inputValue = e.target.value
        setValueTextarea(inputValue)
    }
    //END FORM HANDLERS

    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    //LOAD EVENTS FOR STUDY TO STATE
    const loadEvents = async () => {
        if (studyId) {
            const el = await fetch(directusUrl() + 'items/gw_Event?filter[study][_eq]=' + studyId + '&filter[event_status][_eq]=open', requestOptions); //already isOPen //poner open
            const json = await el.json();
            console.log('json:', json)
                setEvent(json.data)
        }
    }
    useEffect(() => {
        loadEvents()
    }, [studyId])

    //Create request
    const createAndSendRequest = async () => {
        console.log("Selected event",selectedEvent)
        setShowSend(false);
        let userForm = { name: valueName, email: valueEmail, additionalComments: valueTextarea };
        let userDirectus = await checkUserDirectus(valueEmail);
        let user;
        if (userDirectus) {
            console.log("User in directus", userDirectus);
            user = userDirectus.user_id;
            
        } else {
            console.log("User not in directus, proceed to create.")
            let userCreated = await newUserDirectus(userForm);
            user = userCreated;
        }
        let data = selectedEvent ? {
            "id": selectedEvent.id,
            "students": [...selectedEvent.students,
            {
                "id": user,
                "gw_User_user_id": user
            }
            ]
        } : null;
        try { //sending request to directus
            console.log("Data to directus patch:", data);
            if (data) { //meaning there is an event. If no event, the regitration is in groups without URL
                const registrationCorrect = await registerToEvent(data);
                if (registrationCorrect.status !== 200) {
                    throw new Error()
                }
                const responseRegistration = await registrationCorrect.json();
                console.log("DATA", responseRegistration)
                proposalConfirmed('registration')
            } else {
                console.log("User for edit",user)
                const editCorrect = await editUserDirectus(user);
                if (editCorrect.status !== 200) {
                    throw new Error()
                }
                const responseEdit = await editCorrect.json();
                console.log("DATA Edit user", responseEdit);
                const webHookToEdit = await webhookDirectus(responseEdit.data);
                console.log("Edited:", webHookToEdit)
                proposalConfirmed('registration')
            }
            
        } catch (error) {
            console.log("Error in registration. Could'nt add student to event.",error);
            denyToast();
            resetForms();
        }
    }
    const proposalConfirmed = (string) => {
        toast({
            title: 'Request created!',
            description: "We've received your " + string + " request. Thanks for reaching to us.",
            status: 'success',
            duration: 4000,
            isClosable: true,
        })
    }

    const denyToast = () => {
        toast({
            title: 'There has been an error sending your form',
            description: 'If you are sure all the information is correct, write us on Messenger',
            status: 'error',
            isClosable: true,
            duration: 4500
        })
    }
    //End create request

    return (
        <Container bg="#f3f3f3" maxW="full" width={studyId ? '100%':'auto'} mt={16} centerContent overflow="hidden" borderRadius="xl">
            <Flex>
                <Box
                    bg="black.900"
                    color="black"
                    borderRadius="lg"
                    m={{ sm: 1, md: 16, lg: 6 }}
                    p={{ sm: 1, md: 5, lg: 6 }}
                    sx={{ textAlign: "center", justifyContent: "center" }}
                >
                    <Box >
                        <Stack flexDirection={["column","column","row"] }
                            className="wrap-master">
                            {studyId ? < VStack className="wrap-item" >
                                <Box>
                                    <Heading>Register now</Heading>
                                    <Text mt={{ sm: 3, md: 3, lg: 5 }} color="gray.900">
                                        Select your date below!
                                    </Text>
                                    <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                                    {/* Buttons times for the ZBS*/}
                                        <VStack pl={0} spacing={10} alignItems="center">
                                            {event.length > 0 ? (
                                                event.map((ev, index) => {
                                                    let eventTimeString = formatDate(ev.startDate);
                                                    let everyString = " Every " + formatDay(ev.weekday) + ", " + formatTime(ev.startDate);
                                                    return (<VStack >
                                                        <Text fontFamily='Epilogue' fontSize='xl'>Start: {eventTimeString}</Text>
                                                        <Button
                                                            key={ev.id}
                                                            size="xl"
                                                            height="65px"
                                                            width="350px"
                                                            variant="outline"
                                                            color="black.900"
                                                            _hover={{ border: '2px solid #1C6FEB' }}
                                                            _active={{
                                                                bg: '#dddfe2',
                                                                transform: 'scale(0.98)',
                                                                borderColor: '#bec3c9',
                                                            }}
                                                            _focus={{
                                                                boxShadow:
                                                                    '0 0 1px 6px rgba(100, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
                                                            }}
                                                            leftIcon={<MdLocationOn color="#1970F1" size="33px" />}
                                                            onClick={() => { setSelectedEvent(ev);console.log("EV",ev) }}
                                                        >
                                                            {everyString}
                                                        </Button>)
                                                        <Divider colorScheme='messenger' />
                                                    </VStack>
                                                        
                                                )})) : (<Alert status='info'>
                                                    <AlertIcon />
                                                    More dates coming soon. Get ready!
                                                </Alert>)
                                            }
                                            <Text fontFamily="Epilogue"> {selectedEvent != null ? "Selected days: " + formatDay(selectedEvent.weekday) : "Please select from above"}</Text>
                                        </VStack>
                                    </Box>
                                </Box>
                            </VStack>
                                : false
                            }
                            {/*This box will show if the user enters the URL without a sutyd in parameters*/}
                            <Box flexDirection={'column'} alignItems='center' className={styles.formItem} p={[".55rem",".55rem","2rem"] }>
                                {studyId ?
                                    false :
                                    <Text fontSize='lg' mt={{ sm: 3, md: 3, lg: 3 }} mb={{ sm: 8, md: 8, lg: 8 }} color="gray.900">
                                        We have a perfect study for you! <br />
                                        Register and we will contact you!                                    </Text>
                                }
                                <Box bg="white" borderRadius="lg" border="1px solid #ddd">
                                    <Box m={8} color="#0B0E3F" fontFamily='Epilogue'>
                                        <VStack spacing={5}>
                                            <FormControl id="name">
                                            {/*NAME*/}
                                                <FormLabel>Your Name</FormLabel>
                                                <InputGroup borderColor="#E0E1E7">
                                                    <InputLeftElement pointerEvents="none">
                                                        <BsPerson color="gray.800" />
                                                    </InputLeftElement>
                                                    <Input type="text" size="md" onBlur={handleChangeName} placeholder='e.g. John' />
                                                </InputGroup>
                                            </FormControl>

                                            {/*EMAIL*/}

                                            <FormControl id="email" isRequired>
                                                <FormLabel>Email</FormLabel>
                                                <InputGroup borderColor="#E0E1E7" flexDirection={"column" }>
                                                    <InputLeftElement pointerEvents="none">
                                                        <MdOutlineEmail color="gray.800" />
                                                    </InputLeftElement>
                                                    <Input type="email" size="md"
                                                        placeholder='e.g. johnny@gmail.com'
                                                        onBlur={handleChangeEmail}
                                                        isInvalid={validEmail}
                                                    />
                                                    <FormHelperText>We'll never share your email.</FormHelperText>
                                                </InputGroup>
                                            </FormControl>

                                            {/*ADDITIONAL COMMENT*/}

                                            <FormControl id="additional">
                                                <FormLabel>Additional comments (Optional):</FormLabel>
                                                <Textarea
                                                    borderColor="gray.300"
                                                    _hover={{
                                                        borderRadius: 'gray.300',
                                                    }}
                                                    placeholder='Tell us what you think here...'
                                                    onBlur={handleInputChangeTextarea}
                                                />
                                            </FormControl>
                                            {/*REGISTER BUTTON*/}
                                            <FormControl id="name" float="center">
                                                <Tooltip isDisabled={showSend} hasArrow label={selectedEvent==null ? "A date is required": "A valid email is required"} shouldWrapChildren mt='0' bg='red.600' color='white'>
                                                    <Button disabled={studyId ? (!showSend || !selectedEvent) : (!showSend)} colorScheme='green' onClick={() => {
                                                        createAndSendRequest()
                                                    }
                                                    } >Register!</Button> {/**onClick will call function that collects all form info */}

                                                </Tooltip>
                                            </FormControl>
                                        </VStack>
                                    </Box>
                                </Box>
                            </Box>
                        </Stack>
                    </Box>
                </Box>
            </Flex>
        </Container>
    )
}