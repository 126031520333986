// 1. Import the utilities
import { extendTheme } from '@chakra-ui/react'

// 2. Update the breakpoints as key-value pairs
const breakpoints = {
    sm: '320px',
    md: '770px',
    lg: '960px',
    xl: '1919px',
    '2xl': '2140px',
}

// 3. Extend the theme
const theme = extendTheme({ breakpoints })

export default theme;