import directusUrl from "./urlString";

export default async function getStudies(){
    //ALL STUDIES
    var studies;
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    
    const receive = await fetch(directusUrl() +"items/gw_Study?fields[]=*&fields[]=topics.*&filter[active][_eq]=true", requestOptions);
    const response = await receive.json();
    console.log(response.data);
    studies = response.data; //ALREADY RETURN OBJECT.DATA FROM DIRECTUS
    return studies;
}