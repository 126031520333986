import * as React from "react";
import {
    Container,
    SimpleGrid,
    Image,
    Text,Box,
    Stack,HStack
} from '@chakra-ui/react';
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react'
import { Heading } from '@chakra-ui/react'
import directusUrl from "../utils/urlString";
import Register from '../utils/register'

export default function StudiesSection({ name, studyCover, topics, studyId, comment }) {
    const [topicByStudy, setTopicsByStudy] = React.useState([]);

    React.useEffect(() => {
        topicsLoader();
        console.log("Studies Section studyID", studyId)
    }, [])

    React.useEffect(() => {
        console.log(topicByStudy)
    }, [topicByStudy])

    const topicsLoader =() => {
        let myArray = [];
        topics.map(async (topic) => {
            //console.log(topic)
            try {
                var el = await fetch(directusUrl() + 'items/gw_Topic/' + topic.gw_Topic_topic_id, { method: "GET", redirect: "follow" });
                var json = await el.json();
                if (json) {
                    console.log("JSON", json)
                }
                myArray.push(json.data);
                setTopicsByStudy(current => [...current, json.data])
            } catch (error) {
                console.error("Error",error)
            }
            
        });
    }

    React.useEffect(() => {
        showTopics()
    }, [topicByStudy])

    const showTopics = () => {
        return topicByStudy.map((topic, index) => {
            return topic && index < 6 ?
                <Feature
                icon={
                    <Image src={directusUrl() + "assets/" + topic.topic_icon} color={'yellow.500'} w={8} h={8} alt="topic icon"
                        onError={(e) => (e.currentTarget.src = "https://picsum.photos/200/200")}/>
                }              
                    description={topic.description }
                text={topic.name}
                    key={index}

            /> : false
        })
    };

    const Feature = ({ text, icon, iconBg,description }) => {
        return (
            <AccordionItem>
                <HStack py={2 }>
                    {icon}
                    <AccordionButton>
                        <Box as="span" flex='1' textAlign='left' fontSize='xl'>
                             {text}
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </HStack>
                <AccordionPanel pb={4}>
                    {description}
                </AccordionPanel>
            </AccordionItem>

        )
    }

    return (
            <Container maxW={'5xl'} py={12} >
                
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                    <Stack spacing={4} fontFamily='Epilogue'>

                        <Heading>{name}</Heading>
                        <Text color={'gray.500'} fontSize={'lg'}>
                            {comment}
                        </Text>
                        <Accordion allowMultiple>
                            {
                                showTopics()
                            }
                        </Accordion>
                        {/*</Stack>*/}
                        <HStack >
                            <Text fontSize='xl'>And many more...</Text>
                        </HStack>
                </Stack>
                <Box display="block">
                        <Image
                            rounded={'md'}
                            alt={'feature image'}
                            src={directusUrl()+"assets/"+studyCover
                            }
                            objectFit={'contain'}
                        />
                    </Box>
                </SimpleGrid>
                {/*<RegisterToStudy key={studyId} studyId={studyId} />*/}
                <Register key={studyId} studyId={studyId} />
            </Container>
        )
    }
