import directusUrl from "./urlString";

const registerToEvent = async (props)=>{
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({
  "students": props.students
});

var requestOptions = {
  method: 'PATCH',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};
return fetch(directusUrl()+"items/gw_Event/"+props.id, requestOptions)
}

export default registerToEvent;